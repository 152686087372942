import React, { Component } from "react";
import {
  Card,
  Row,
  Col,
  Select,
  Table,
  Empty,
  Input,
  Tooltip,
  Icon,
  Button,
  Modal,
  notification,
  Switch,
  Radio,
  Popover,
  Checkbox,
} from "antd";
import "./fleet.scss";
import * as actions from "./action";
import { connect } from "react-redux";
import * as utils from "../../../common/utils/utils";
import { canAllow } from "../../../common/utils/utils";
import { permissionTags } from "../../../configs/permissionTags";
import { Constants } from "../../../common/utils/constants";
import SuperSelect from "../../ui/SuperSelect/SuperSelect";
import moment from "moment";
import { apiRequest } from "../../../common/utils/apiGateway";
import NumberFormat from "react-number-format";
import _ from "lodash";
const { confirm } = Modal;
const Option = Select.Option;

class ZonesDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enable: false,
      selectedRowKeys: [],
      selectedRows: [],
      filterValues: {},
      fleetPermission: {
        read: canAllow(permissionTags.hyperlocal.zones.read),
        update: canAllow(permissionTags.hyperlocal.zones.update),
        create: canAllow(permissionTags.hyperlocal.zones.create),
        delete: canAllow(permissionTags.hyperlocal.zones.delete),
        json_redash_permission: canAllow(permissionTags.hyperlocal.zones.json_redash_permission),
        zone_surge: canAllow(permissionTags.hyperlocal.zones.zone_surge),
        rain_surge: canAllow(permissionTags.hyperlocal.zones.rain_surge),
      },
      taggedModal: false,
      taggedClientLists: [],
      taggedClientCities2: [],
      selectedCountCities: 0,
      checkallCities: false,
      seteligibleClients: [],
      seteligibleCitiesKeys: [],
      selectedZone: undefined,
      customerId: [],
      clusterId: undefined,
      hyperlocalServiceableAreaCode: undefined,
      zoneConfigResponse: [],
      taggedFEModal: false,
      showRedash: false,
      demandSurgeModal: false,
      demandSurgeEnteredAmount: undefined,
      rowVal: {},
      isChecked: null,
      isBulkClientTaggedSaving: false,
    };
    this.InputSearchRef = React.createRef();
  }

  componentDidMount() {
    if (
      this.props.locationIds &&
      this.props.cityId
    ) {
      this.setState(
        { locationIds: this.props.locationIds, cityId: this.props.cityId },
        () => {
          this.servicableAreas();
        }
      );
    }
    this.setState(state => ({
      columns: [
        {
          title: "Zone",
          dataIndex: "name",
          key: "zone",
          defaultSortOrder: 'ascend',
          sortDirections: ['ascend', 'descend'],
          sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
          title: "Serving Requests",
          key: "killSwitchStatus",
          dataIndex: "killSwitchStatus",
          render: (data, row) => (
            <Row>
              <Switch
                checked={row?.killSwitchStatus ? false : true}
                onChange={(e, d) => this.hyperlocalEnableCheck(e, row._key)}
                checkedChildren={<Icon type="check" />}
                unCheckedChildren={<Icon type="close" />}
                defaultChecked
                className="switchColor"
              />
            </Row>
          )
        },
        {
          title: "Rain Surge",
          key: "surges",
          dataIndex: "surges",
          render: (data, row) => (
            <Row>
              <Col span={12}>
                <Tooltip
                  placement="top"
                  title={
                    !this.state.fleetPermission.rain_surge
                      ? "You do not have the permission to perform this action. Pls contact your branch admin or sukanya@loadshare.net"
                      : ""
                  }
                >
                  <Switch
                    checked={!!row?.surges?.filter(i => i.surgeType === "RAIN_SURGE")?.length}
                    onChange={(checked, d) => {
                      const isAllow = row?.killSwitchStatus;
                      if (isAllow) {
                        if (checked) {
                          utils.notifyApiError(
                            "Enable Serving Requests to enable Rain Surge"
                          );
                        } else {
                          this.setRainSurge(row, false);
                        }
                      } else {
                        this.setRainSurge(row, checked);
                      }
                    }}
                    disabled={!this.state.fleetPermission.rain_surge}
                    checkedChildren={<Icon type="check" />}
                    unCheckedChildren={<Icon type="close" />}
                    defaultChecked
                    className="switchColor"
                  />
                </Tooltip>
              </Col>
              <Col span={12}>
                {row?.surges?.length &&
                  (row?.surges?.map(item => {
                    if (item.surgeType === "RAIN_SURGE") {
                      return (
                        <>
                          {"Started at "}
                          {moment(item?.startTime).format("hh:mm A")} <br />
                          Ends {moment(item?.expiryTime).fromNow()}
                        </>
                      );
                    } else return null
                  }))
                }
              </Col>
            </Row>)
        },
        {
          title: "Zone Surge",
          key: "zonesurges",
          dataIndex: "zonesurges",
          render: (data, row) => (
            <Row>
              <Col span={12}>
                <Tooltip
                  placement="top"
                  title={
                    !this.state.fleetPermission.zone_surge
                      ? "You do not have the permission to perform this action. Pls contact your branch admin or sukanya@loadshare.net"
                      : ""
                  }
                >
                  <Switch
                    checked={!!row?.surges?.filter(i => i.surgeType === "DEMAND_SURGE")?.length}
                    onChange={(checked, d) => {
                      this.setState({
                        rowVal: row,
                        isChecked: checked,
                      })
                      const isAllow = row?.killSwitchStatus;
                      if (isAllow) {
                        if (checked) {
                          utils.notifyApiError(
                            "Zone surge can only be applied to an active zone. Please enable Serving Requests first"
                          );
                        } else {
                          this.setDemandSurge(row, false);
                        }
                      } else {
                        if (checked) {
                          this.setState({
                            demandSurgeModal: true,
                          })
                        } else {
                          this.setState({
                            demandSurgeEnteredAmount: undefined
                          })
                          this.setDemandSurge(row, checked);
                        }
                      }
                    }}
                    disabled={!this.state.fleetPermission.zone_surge}
                    checkedChildren={<Icon type="check" />}
                    unCheckedChildren={<Icon type="close" />}
                    defaultChecked
                    className="switchColor"
                  />
                </Tooltip>
              </Col>
              <Col span={16}>
                {
                  row?.surges?.length &&
                  (row?.surges?.map(item => {
                    if (item.surgeType === "DEMAND_SURGE") {
                      return (
                        <div>
                          {`Started at `}
                          {moment(item?.startTime).format("hh:mm A")} <br />
                          Ends {moment(item?.expiryTime).fromNow()} <br />
                          {`Zone surge amount is ${item?.amount}`}
                        </div>
                      );
                    }
                  }))
                }
              </Col>
            </Row>)
        },
        {
          title: "Tagged Client",
          dataIndex: "taggedCustomers",
          key: "taggedCustomers",
          render: (data, row) => row?.taggedCustomers?.join(", ")
        },
        {
          title: "Action",
          key: "action",
          render: (data, row) => (
            <>
              <div style={{ display: "flex", width: "5vw" }}>
                <Icon type="edit" onClick={() => this.openTaggedClients(row)} style={{ marginRight: "10px" }} />
                {this.state.fleetPermission.json_redash_permission &&
                  <Tooltip placement="top" title={"JSON Data"}>
                    <Icon type="eye" onClick={() => this.fmLmSearchHandler(row)} style={{ marginRight: "10px" }} />
                  </Tooltip>
                }
                {this.state.fleetPermission.json_redash_permission &&
                  <Tooltip placement="top" title={"Redash Data"}>
                    <Icon type="eye" onClick={() => this.showRedashHandler(row)} />
                  </Tooltip>
                }
              </div>
            </>
          )
        }
      ]
    }));
  }

  openTaggedClients = row => {
    this.setState({ taggedModal: true, selectedZone: row.name }, () => {
      if (this.state.taggedClientLists.length <= 0) {
        apiRequest({
          url: `/v1/hyperlocal-serviceable-areas/${row.name}/getTaggedCustomers`,
          method: "GET"
        }).then(res => {
          if (utils.checkHttpStatus(res?.data?.status)) {
            this.setState(
              {
                taggedClientLists: res?.data?.response?.customers,
                taggedClientCities2: res?.data?.response?.customers,
                seteligibleCitiesKeys: [],
                checkallCities: false
              },
              () => {
                const checked = this.state.taggedClientLists.filter(
                  cl => cl?.isTagged
                ).length;
                this.setState({ selectedCountCities: checked });
              }
            );
          }
        });
      }
    });
  };

  fmLmSearchHandler = row => {
    this.setState({ taggedFEModal: true, selectedZone: row.name }, () => {
      apiRequest({
        url: "/v1/hyperlocal-zone-config/getbyFliter",
        method: "POST",
        data: {
          "partnerIds": [],
          "hyperlocalZoneClusterIds": [],
          "hyperlocalServiceableAreaCodes": [this.state.selectedZone],
          "customerIds": [],
          "status": []
        }
      }).then(res => {
        this.setState({
          zoneConfigResponse: res.data.response.zoneConfigResponse,
        })
        this.state.zoneConfigResponse && this.state.zoneConfigResponse.map(zones => {
          this.setState({
            customerId: zones.customerId,
            clusterId: zones.zoneClusterId,
            hyperlocalServiceableAreaCode: zones.hyperlocalServiceableAreaCode
          })
        })
      })
      // }
    });
  };

  showRedashHandler = row => {
    this.setState({ showRedash: true, selectedZone: row.name }, () => {
      apiRequest({
        url: "/v1/hyperlocal-zone-config/getbyFliter",
        method: "POST",
        data: {
          "partnerIds": [],
          "hyperlocalZoneClusterIds": [],
          "hyperlocalServiceableAreaCodes": [this.state.selectedZone],
          "customerIds": [],
          "status": []
        }
      }).then(res => {
        this.setState({
          zoneConfigResponse: res.data.response.zoneConfigResponse,
        })
        this.state.zoneConfigResponse && this.state.zoneConfigResponse.map(zones => {
          this.setState({
            customerId: zones.customerId,
            clusterId: zones.zoneClusterId,
            hyperlocalServiceableAreaCode: zones.hyperlocalServiceableAreaCode
          })
        })
      })
    });
  }

  closeTaggedClients = () => {
    this.setState({
      taggedModal: false,
      taggedClientLists: [],
      selectedZone: undefined,
      taggedClientCities2: [],
      taggedFEModal: false,
      zoneConfigResponse: [],
      showRedash: false,
      clusterId: undefined,
      customerId: [],
      hyperlocalServiceableAreaCode: undefined
    });
  };

  checkAllCities = e => {
    let seteligibleClients = [...this.state.taggedClientLists];
    if (e.target.checked === true) {
      seteligibleClients.map(x => {
        x.isTagged = true;
      });
      this.setState({
        taggedClientLists: seteligibleClients,
        checkallCities: true,
        seteligibleCitiesKeys: seteligibleClients,
        selectedCountCities: seteligibleClients.length
      });
    } else {
      seteligibleClients.map(x => {
        x.isTagged = false;
      });
      this.setState({
        taggedClientLists: seteligibleClients,
        checkallCities: false,
        seteligibleCitiesKeys: seteligibleClients,
        selectedCountCities: 0
      });
    }
  };

  setEligibleCity = item => {
    let count = 0;
    let seteligibleClients = [...this.state.taggedClientLists];
    let seteligibleCitiesKeys = [...this.state.seteligibleCitiesKeys];
    seteligibleClients.map(x => {
      if (item.customerId === x.customerId) {
        x.isTagged = !x.isTagged;
        seteligibleCitiesKeys.push(item);
      }
    });
    seteligibleClients.map(x => {
      if (x.isTagged) {
        count++;
      }
    });
    let allchecked = this.checkAllStatus2(seteligibleClients);
    this.setState({
      seteligibleCitiesKeys: seteligibleCitiesKeys,
      taggedClientLists: seteligibleClients,
      checkallCities: allchecked,
      selectedCountCities: count
    });
  };

  checkAllStatus2 = data => {
    let isSame = true;
    for (let i = 0; i < data.length; i++) {
      if (data[i].isTagged === false) {
        isSame = false;
        break;
      }
    }
    return isSame;
  };

  applySearchCities = () => {
    this.setState({
      taggedClientLists: this.state.taggedClientLists
    });
  };

  showList = e => {
    let checkgeoJson = [...this.state.taggedClientCities2];
    let filtergeoJson = [];
    if (e.target.value && e.target.value !== "") {
      filtergeoJson = checkgeoJson.filter(
        x =>
          x.customerCode.toLowerCase().indexOf(e.target.value.toLowerCase()) !==
          -1
      );
    } else {
      filtergeoJson = checkgeoJson;
    }
    this.setState({
      taggedClientLists: filtergeoJson
    });
  };

  saveTaggedClient = () => {
      this.setState({
            isBulkClientTaggedSaving: true,
          });
    let seteligibleCitiesKeys = [...this.state.seteligibleCitiesKeys];
    let eligibleKeys = _.uniqBy(seteligibleCitiesKeys, "customerCode");
    let data = {
      action: "SAVE_TAGGED_CUSTOMER",
      taggedCustomers: eligibleKeys
    };

    apiRequest({
      url: `/v1/hyperlocal-serviceable-areas/${this.state.selectedZone}/saveTaggedCustomers`,
      method: "POST",
      data
    }).then(res => {
      if (utils.checkHttpStatus(res?.data?.status)) {
        if (res?.data?.response?.sync) {
          utils.notifyApiSuccess("Customer Tagged Successfully!", "SUCCESS");
          this.closeTaggedClients();
          this.servicableAreas();
        } else {
          utils.notifyApiError("Customer Tagging Failed!", "FAILED!");
        }
         this.setState({
            isBulkClientTaggedSaving: false,
          });
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.cityList !== this.props.cityList) {
      this.setState({ cityList: this.props.cityList });
    }

    if (prevProps.cityListById !== this.props.cityListById) {
      this.setState({ cityListById: this.props.cityListById });
    }

    if (prevProps.zones !== this.props.zones) {
      if (this.state.filterValues) {
        this.applySearch(this.state.filterValues, this.props.zones);
      } else {
        this.setState({ zones: this.props.zones });
      }
    }
  }

  hyperlocalEnableCheck = (e, values) => {
    let payload = {
      cityId: this.state.cityId,
      locationIds: this.state.locationIds,
      keys: [values],
      killSwitchStatus: !e
    };
    this.props.zoneToggle(payload).then(res => {
      if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
        this.setState({
          zonekill: res?.data?.response
        });
      } else {
        notification["error"]({
          message: "ERROR",
          description: res?.data?.status?.message,
          placement: "bottomRight"
        });
      }
    });
  };

  onLocationChange = e => {
    if (e) {
      this.props.getCityListById(e);
      this.setState({ cityId: e, locationIds: [] });
    } else {
      this.setState({ locationIds: [] });
    }
  };

  onpartnerChange = e => {
    if (e) {
      this.setState({ locationIds: e });
    } else {
      this.setState({ locationIds: [] });
    }
  };

  zoneSearch = e => {
    let zoneData = [...this.props.zones];
    let filter = { partialContentSearch: undefined };
    if (e.target.value === "") {
      this.setState({
        zones: zoneData,
        filterValues: filter
      });
    }
  };

  applySearch = (filter, data) => {
    let zoneData = [];
    let filterData = [];
    if (data) {
      zoneData = data;
    } else {
      zoneData = [...this.props.zones];
    }
    if (filter.partialContentSearch) {
      const searchInput = utils.trimEachWaybill(filter.partialContentSearch);
      zoneData.map(x => {
        searchInput.map(y => {
          if (x._key.indexOf(y) !== -1) {
            filterData.push(x);
          }
        });
      });
    } else {
      filterData = zoneData;
    }
    this.setState({
      zones: filterData,
      filterValues: filter
    });
  };

  servicableAreas = () => {
    let payload = {
      cityId: this.state.cityId,
      locationIds: this.state.locationIds
    };
    this.props.hyperlocalServicableAreas(payload);
  };

  checkall = setValue => {
    let selecteditems = [...this.state.selectedRows];
    let zones = [...this.state.zones];
    let kaysArray = [];
    selecteditems.map(x => {
      kaysArray.push(x._key);
    });
    let payload = {
      cityId: this.state.cityId,
      locationIds: this.state.locationIds,
      killSwitchStatus: this.state.enable
    };
    if (zones.length !== selecteditems.length) {
      payload.keys = kaysArray;
    }
    if (setValue === "disabled") {
      payload.killSwitchStatus = true;
    } else {
      payload.killSwitchStatus = false;
    }
    this.props.zoneToggle(payload).then(res => {
      if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
        this.setState({
          enable: !this.state.enable,
          selectedRows: [],
          selectedRowKeys: []
        });
      } else {
        notification["error"]({
          message: "ERROR",
          description: res?.data?.status?.message,
          placement: "bottomRight"
        });
      }
    });
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedRowKeys: selectedRowKeys,
      selectedRows: selectedRows
    });
  };

  setLocationEndData = data => {
    const endLocationsData = [];
    if (data && data.length > 0) {
      data.forEach(x => {
        endLocationsData.push(
          <Option key={x.id} value={x.id} label={x.name}>
            {x.name}
          </Option>
        );
      });
      return endLocationsData;
    } else {
      return;
    }
  };

  setLocationpartnerData = data => {
    const endLocationsData = [];
    if (data && data.length > 0) {
      data.forEach(x => {
        endLocationsData.push(
          <Option key={x.id} value={x.id} label={x.alias}>
            {x.alias}
          </Option>
        );
      });
      return endLocationsData;
    } else {
      return;
    }
  };

  setRainSurge = (zone, isActive) => {
    const payload = {
      requests: [
        {
          zoneKey: zone._key,
          referenceId: moment().valueOf(),
          surgeType: "RAIN_SURGE",
          timeToExpire: null,
          reason: null
        }
      ]
    };
    actions.setRainSurge(payload, isActive).then(resp => {
      this.servicableAreas();
    });
  };

  closeDemandSurgeModal = () => {
    this.setState({
      demandSurgeModal: false,
      demandSurgeEnteredAmount: undefined,

    });
  }

  closeDemandSurgeError = () => {
    this.setState({
      demandSurgeAmountError: false,
      demandSurgeEnteredAmount: undefined
    })
  }

  demandSurgeInputAmountHandler = (e) => {
    this.setState({
      demandSurgeEnteredAmount: e.target.value,
    });
  }

  setDemandSurge = (zone, isActive) => {
    const payload = {
      requests: [
        {
          zoneKey: zone._key,
          referenceId: moment().valueOf(),
          surgeType: "DEMAND_SURGE",
          amount: Number(this.state.demandSurgeEnteredAmount),
          timeToExpire: null
        }
      ]
    };
    actions.setDemandSurge(payload, isActive).then(resp => {
      this.setState({ demandSurgeEnteredAmount: undefined })
      this.servicableAreas();
    });
  };

  demandSurgeSubmitHandler = () => {
    if (this.state.demandSurgeEnteredAmount >= 1 && this.state.demandSurgeEnteredAmount <= 99) {
      this.setDemandSurge(this.state.rowVal, this.state.isChecked);
      this.setState({
        demandSurgeModal: false,
        demandSurgeAmountError: false,
      });
    } else {
      this.setState({ demandSurgeAmountError: true })
    }
  }

  resetHandler = () => {
    this.setState({ demandSurgeEnteredAmount: undefined })
  }

  render() {
    const { selectedRowKeys, selectedRows } = this.state;
    const rowSelection = {
      selectedRowKeys,
      selectedRows,
      onChange: this.onSelectChange,
      hideDefaultSelections: true
    };
    return (
      <>
        <Card
          title="Zones"
          className=''
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: 20 }}>
            <Col span={8}>
              <Input.Search
                placeholder="Search"
                allowClear
                ref={this.InputSearchRef}
                disabled={this.state?.zones ? false : true}
                onChange={e => this.zoneSearch(e)}
                onSearch={value =>
                  this.applySearch({
                    partialContentSearch: value.toLowerCase().trim()
                  })
                }
                enterButton
              />
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              {this.state.selectedRows && this.state.selectedRows.length > 0 && (
                <Button type="danger" onClick={() => this.checkall("disabled")}>
                  Disable Zones
                </Button>
              )}
            </Col>
            <Col span={4}>
              {this.state.selectedRows && this.state.selectedRows.length > 0 && (
                <Button
                  className="success_cust"
                  onClick={() => this.checkall("enabled")}
                >
                  Enable Zones
                </Button>
              )}
            </Col>
            <Col span={2}>
              <Tooltip placement="top" title={"Reload"}>
                <Button
                  block
                  onClick={this.servicableAreas}
                  type="primary"
                  style={{ marginLeft: 2 }}
                >
                  <Icon type="reload" />
                </Button>
              </Tooltip>
            </Col>
          </Row>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            style={{ marginTop: 20 }}
          >
            <Col className="gutter-row" span={24}>
              <Table
                bordered
                locale={{
                  emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }}
                rowSelection={rowSelection}
                loading={this.props.fleetStatus?.loading}
                dataSource={this.state.zones}
                pagination={false}
                footer={() =>
                  `${this.state.selectedRows.length} Total Selected`
                }
                columns={this.state.columns}
                rowKey={record => record._key}
              />
            </Col>
          </Row>
          <Modal
            title={`Selected Clients (${this.state.selectedCountCities})`}
            visible={this.state.taggedModal}
            // onOk={this.saveTaggedClient}
            onCancel={this.closeTaggedClients}
            maskClosable={false}
            className=""
            footer={null}
            width={320}
          >
            <Row>
              <Col span={24}>
                <Col span={24}>
                  <Checkbox
                    className="topCheck"
                    checked={this.state.checkallCities}
                    onChange={this.checkAllCities}
                  >
                    All Clients
                  </Checkbox>
                  <Input.Search
                    placeholder="Search"
                    allowClear
                    onChange={this.showList}
                    disabled={this.state?.taggedClientLists ? false : true}
                    onSearch={this.applySearchCities}
                    enterButton
                    maxLength={2}
                  />
                </Col>
              </Col>
              <Col span={24}>
                <div ghost="true">
                  <div className="userBox">
                    {this.state.taggedClientLists &&
                      this.state.taggedClientLists.map((item, index) => (
                        <Col key={index} span={24}>
                          <Col span={2}></Col>
                          <Col span={4}>
                            <Checkbox
                              checked={item.isTagged}
                              onChange={() => this.setEligibleCity(item)}
                            ></Checkbox>
                          </Col>
                          <Col span={16}>
                            <div className="username">{item.customerCode}</div>
                          </Col>
                          <Col span={2}></Col>
                        </Col>
                      ))}
                  </div>
                  <div style={{ marginTop: 20, textAlign: "center" }}>
                    <Button
                      style={{ width: 200 }}
                      type="primary"
                      onClick={this.saveTaggedClient}
                      disabled={this.state.isBulkClientTaggedSaving}
                      loading={this.state.isBulkClientTaggedSaving}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Modal>

          <Modal
            title="Zone Config Data"
            visible={this.state.taggedFEModal}
            onCancel={this.closeTaggedClients}
            maskClosable={false}
            className=""
            footer={null}
            width={800}
          >
            <div style={{ maxHeight: 450, overflow: "scroll" }}>
              <pre style={{ width: "50rem" }}>{JSON.stringify(this.state.zoneConfigResponse, null, 2)}</pre>
            </div>
          </Modal>
          <Modal
            visible={this.state.showRedash}
            onCancel={this.closeTaggedClients}
            maskClosable={false}
            className=""
            footer={null}
            width={880}
          >
            <div style={{ maxHeight: 450, overflow: "scroll" }}>
              <iframe src={`https://redash.loadshare.net/embed/query/7086/visualization/11482?api_key=ryI2xs5oo3VXSrX1L8NLYAfaYNOOkMckdusv4cRO&p_Zones=${this.state.hyperlocalServiceableAreaCode}&p_Cluster=${this.state.clusterId}&p_Customer=${this.state.customerId}`} width="800" height="391"></iframe>
            </div>
          </Modal>
          <Modal
            title={`Please enter amount between 1 to 99`}
            visible={this.state.demandSurgeModal}
            onCancel={this.closeDemandSurgeModal}
            maskClosable={false}
            width={400}
            footer={[
              <Button onClick={this.demandSurgeSubmitHandler} key="submit" type="primary" disabled={!this.state.demandSurgeEnteredAmount}>
                Submit
              </Button>,
              <Button onClick={this.resetHandler} key="submit">Reset</Button>
            ]}
          >
            <div>
              <Input
                placeholder="Enter the amount"
                onChange={this.demandSurgeInputAmountHandler}
                value={this.state.demandSurgeEnteredAmount}
                min={1}
                max={99}
                type="text"
                defaultValue=""
                style={{ width: "23vw", height: "36px", padding: "0px 0px 0px 12px", borderRadius: "4px", border: "solid 1px #d9d9d9" }}
              />
            </div>
          </Modal>
          <Modal
            visible={this.state.demandSurgeAmountError}
            onCancel={this.closeDemandSurgeError}
            onOk={this.closeDemandSurgeError}
            maskClosable={false}
            width={300}
          >Amount should be a number between 1 to 99. Please enter again.</Modal>
        </Card >
      </>
    );
  }
}

const mapStateToProps = state => ({
  fleetStatus: state.FleetReducer,
  cityList: state.FleetReducer?.cityList?.citiesDetails,
  cityListById: state.FleetReducer?.locations,
  zones: state.FleetReducer?.zones
});

const mapDispatchToProps = dispatch => ({
  getCityList: () => dispatch(actions.getCityList()),
  getCityListById: cityId => dispatch(actions.getCityListById(cityId)),
  fetchZoneOptions: () => dispatch(actions.fetchZoneOptions()),
  hyperlocalServicableAreas: payload =>
    dispatch(actions.hyperlocalServicableAreas(payload)),
  zoneToggle: payload => dispatch(actions.zoneToggle(payload))
});
export default connect(mapStateToProps, mapDispatchToProps)(ZonesDashboard);
